<template>
  <div class="container is-fluid">
    <page-header :entity="$EntitiesName.Import">
      <h1 class="title" data-test="sel-project-header">
        Import des sous-taches OSCAR
      </h1>
      <template #actions>
        <button v-if="!isImporting && entitiesToDelete.length > 0" class="button is-danger" @click="deleteEntities">Supprimer</button>
        <button v-if="!isImporting" class="button is-warning" @click="isImporting = !isImporting">Importer</button>
        <button v-if="isImporting" class="button is-warning" @click="isImporting = !isImporting">Annuler</button>
      </template>
    </page-header>
    
    <datatable v-if="!isImporting" :fetch="$Api.OscarSubTask.fetchOscarSubTasks" ref="datatable" :entity="$EntitiesName.OscarSubTask" v-model="entitiesToDelete" enableDownload>
      <div slot="oscarSubProject" slot-scope="{ item }" title="Sous-Projet" :sortable="true">{{ item.oscarSubProject && item.oscarSubProject.oscarSubProjectName }}</div>
      <div slot="taskName" slot-scope="{ item }" title="Tâche" :sortable="true" sortBy="oscarSubTaskName.taskName">{{ item.oscarSubTaskName && item.oscarSubTaskName.taskName }}</div>
      <div slot="oscarId" slot-scope="{ item }" title="Id sous-tâche" :sortable="true">{{ item.oscarId }}</div>
      <div slot="name" slot-scope="{ item }" title="Sous-tâche" :sortable="true" sortBy="oscarSubTaskName.subTaskName">{{ item.oscarSubTaskName && item.oscarSubTaskName.subTaskName }}</div>
      <div slot="startDate" slot-scope="{ item }" title="Date de début" nofilter :sortable="true">{{ item.startDate }}</div>
      <div slot="endDate" slot-scope="{ item }" title="Date de fin" nofilter :sortable="true">{{ item.endDate }}</div>
      <div slot="theoreticalEndDate" slot-scope="{ item }" title="Date de fin théorique" :sortable="true">{{ item.theoreticalEndDate }}</div>
      <div slot="deliveryDate" slot-scope="{ item }" title="Date de livraison objectif" :sortable="true">{{ item.deliveryDate }}</div>
      <div slot="period" slot-scope="{ item }" title="Délai" :sortable="true">{{ item.period }}</div>
      <div slot="active" slot-scope="{ item }" title="Ouverte" :sortable="true">{{ item.active }}</div>
      <div slot="status" slot-scope="{ item }" title="Statut" class="has-text-right" :sortable="true">{{ item.status }}</div>
      <div slot="stakeholder" slot-scope="{ item }" title="Nom acteur" class="has-text-right" :sortable="true">{{ item.stakeholder }}</div>
      <div slot="progress" slot-scope="{ item }" title="avancement" class="has-text-right" :sortable="true">{{ item.progress }}</div>
    </datatable>
    
    <div v-if="isImporting" class="panel infos">
      <div class="panel-heading has-background-primary">Copier/Coller pour importer</div>
      <div class="panel-block is-block no-border">
        <handsontable :columns="columns" :import="importSubTasks"/>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: 'oscar_subtasks_import',
  data: function() {
    return {
      isImporting: false,
      entitiesToDelete: [],
      columns: [
        {
          key: 'oscarSProjectId',
          label: 'sprojet_id',
          required: true,
        },
        {
          key: 'taskName',
          label: 'tache_nom',
          required: true,
        },
        {
          key: 'oscarId',
          label: 'stache_id',
          required: true,
        },
        {
          key: 'name',
          label: 'stache_nom',
          required: true,
        },
        {
          key: 'startDate',
          label: 'stache_date_debut',
        },
        {
          key: 'endDate',
          label: 'stache_date_fin',
        },
        {
          key: 'theoreticalEndDate',
          label: 'stache_date_fin_theorique',
          required: true,
        },
        {
          key: 'deliveryDate',
          label: 'stache_date_livraison_objectif',
          required: true,
        },
        {
          key: 'period',
          label: 'stache_delai',
          required: true,
        },
        {
          key: 'active',
          label: 'stache_ouverte',
          required: true,
        },
        {
          key: 'status',
          label: 'stache_statut',
        },
        {
          key: 'stakeholder',
          label: 'acteur_nom',
          required: true,
        },
        {
          key: 'progress',
          label: 'stache_avancement',
          required: true,
        },
      ]
    };
  },
  mounted() {
  },
  computed: {
    selectedToUrl() {
      return this.entitiesToDelete.map(object => object.id).toString();
    },
  },
  methods: {
    importSubTasks(data) {
      return this.$Api.Import.importOscarSubTasks(data);
    },
    deleteEntities() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer ces éléments ?",
        () =>
          axios.delete(`/oscar-subtasks?selected=${this.selectedToUrl}`).then((res) => {
            if (res.data.success) {
              this.$refs.datatable.refresh()
              this.entitiesToDelete = []
            }
          })
      );
    },
  }
}
</script>
<style>

</style>
